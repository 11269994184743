@tailwind base;
@tailwind components;
@tailwind utilities;
#container {
  width: 100px;
  height: 125px;
  margin: auto auto;
}

.loading-title {
  display: block;
  text-align: center;
  font-size: 20;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-bottom: 15px;
  color: #888;
}

.loading-circle {
  display: block;
  border-left: 5px solid;
  border-top-left-radius: 100%;
  border-top: 5px solid;
  margin: 5px;
  animation-name: Loader_611;
  animation-duration: 1500ms;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.sp1 {
  border-left-color: #f44336;
  border-top-color: #f44336;
  width: 40px;
  height: 40px;
}

.sp2 {
  border-left-color: #ffc107;
  border-top-color: #ffc107;
  width: 30px;
  height: 30px;
}

.sp3 {
  width: 20px;
  height: 20px;
  border-left-color: #8bc34a;
  border-top-color: #8bc34a;
}

@keyframes Loader_611 {
  0% {
    transform: rotate(0deg);
    transform-origin: right bottom;
  }

  25% {
    transform: rotate(90deg);
    transform-origin: right bottom;
  }

  50% {
    transform: rotate(180deg);
    transform-origin: right bottom;
  }

  75% {
    transform: rotate(270deg);
    transform-origin: right bottom;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: right bottom;
  }
}
.pagemtr-timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  @apply bg-slate-200;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  @apply bg-slate-200;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  @apply bg-slate-600;
}

.pagemtr-timeline ul li:nth-child(odd) .timeline-icon {
  top: 24px;
  right: -14px;
}

.pagemtr-timeline ul li:nth-child(odd) .action-btn {
  justify-content: end;
}

.pagemtr-timeline ul li:nth-child(even) .action-btn {
  justify-content: start;
}

.pagemtr-timeline ul li:nth-child(even) .timeline-icon {
  top: 24px;
  left: -10px;
}

.pagemtr-timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}

@media only screen and (max-width: 767px) {
  .pagemtr-timeline ul li:nth-child(odd),
  .pagemtr-timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
  }

  .pagemtr-timeline ul li:nth-child(odd) .timeline-icon,
  .pagemtr-timeline ul li:nth-child(even) .timeline-icon {
    top: 12px;
    left: 0px;
  }

  .pagemtr-timeline ul li:nth-child(odd) .timeline-icon {
    right: unset;
  }

  .pagemtr-timeline ul li:nth-child(odd) .action-btn {
    justify-content: start;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-calendar-wrapper {
  border: 1px solid #e5e5e5 !important;
  padding: 25px;
}
.calender-wrap .react-datepicker-popper {
  width: 600px;
}
.calender-wrap {
  position: relative;
  z-index: 99;
}
.calender-wrap .react-datepicker-popper {
  inset: unset !important;
  transform: unset !important;
  right: 0px !important;
  top: 30px !important;
}
.react-datepicker-popper {
  z-index: 111 !important;
}
.custom-calendar-wrapper .react-datepicker__header {
  background-color: #fff;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  margin: 0;
  padding: 0.166rem;
  border-radius: 0;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range,
.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range {
  background-color: #f2f7fe;
  color: #000;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  width: 36px;
  height: 36px;
  padding: 4px 0px;
  vertical-align: middle;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start,
.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected {
  background-color: #2c6ecb;
  color: white;
  font-weight: bold;
  border-radius: 18px 0 0 18px;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
  background-color: #2c6ecb;
  color: #fff;
  border-radius: 0 18px 18px 0 !important;
}

.same-day
  .custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
  background-color: #2c6ecb;
  color: #fff;
  border-radius: 18px 18px 18px 18px !important;
}

.custom-calendar-wrapper
  .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range.react-datepicker__day--weekend.react-datepicker__day--outside-month {
  background-color: transparent !important;
  color: #000 !important;
}
.custom-calendar-wrapper
  .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--outside-month {
  background-color: transparent !important;
  color: #000 !important;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: transparent;
  font-weight: 900;
  color: #000;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-range {
  background-color: #2c6ecb;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--weekend {
  border-radius: 18px 0 0 18px;
}

.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--weekend:first-child {
  border-radius: 18px 0 0 18px;
}

.react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week {
  margin-bottom: 2px;
}
.custom-calendar-wrapper
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--outside-month {
  padding: 4px 0;
  background-color: transparent;
  opacity: 0.4;
}

.custom-calendar-wrapper .react-datepicker__navigation-icon--previous::before {
  background-image: url(../left-arrow.png);
  border: 0;
  width: 15px;
  transform: none;
  height: 15px;
  background-repeat: no-repeat;
  margin-top: 6px;
}
.custom-calendar-wrapper .react-datepicker__navigation-icon--next::before {
  background-image: url(../right-arrow.png);
  border: 0;
  width: 17px;
  transform: none;
  height: 15px;
  background-repeat: no-repeat;
  margin-top: 6px;
}
.custom-calendar-wrapper .react-datepicker__current-month {
  font-size: 16px;
  line-height: 24px;
  color: #202223;
  font-family: helvetica;
  margin-bottom: 10px;
}

.custom-calendar-wrapper
  .react-datepicker__day-names
  .react-datepicker__day-name {
  font-size: 12px;
  line-height: 20px;
  color: #6d7175;
  width: 36px;
  margin: 0;
}

.custom-calendar-wrapper .react-datepicker__navigation--previous {
  left: -5px;
}
.custom-calendar-wrapper .react-datepicker__navigation--next {
  right: -5px;
}
/* end */

.custom-calendar-wrapper {
  background-color: #fff;
}
.custom-calendar-wrapper .relative.my-3:after {
  content: "";
  display: block;
  clear: both;
}
