@font-face {
    font-family: 'icomoon';
    src:  url('./assets/fonts/icomoon.eot?fuasyh');
    src:  url('./assets/fonts/icomoon.eot?fuasyh#iefix') format('embedded-opentype'),
      url('./assets/fonts/icomoon.ttf?fuasyh') format('truetype'),
      url('./assets/fonts/icomoon.woff?fuasyh') format('woff'),
      url('./assets/fonts/icomoon.svg?fuasyh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-events-monitor:before {
    content: "\e900";
  }
  .icon-javascript-profiler:before {
    content: "\e901";
  }
  .icon-playground:before {
    content: "\e902";
  }
  .icon-quick-status:before {
    content: "\e903";
  }
  .icon-recommendation:before {
    content: "\e904";
  }
  .icon-resources-profiler:before {
    content: "\e905";
  }
  .icon-static-resources:before {
    content: "\e906";
  }
  .icon-test-schedule:before {
    content: "\e907";
  }
  .icon-web-crawler:before {
    content: "\e908";
  }

  .icon-website-Insight:before {
    content: "\e912";
  }
  .icon-website-safety:before {
    content: "\e917";
  }
  .icon-website-safety:before {
    content: "\e917";
  }
  .icon-drop-down::before {
    content: "\e914";
  }
  .icon-drop-up::before {
    content: "\e913";
  }
