@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.before-bg-blur::before {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  backdrop-filter: blur(2px);
}

/* loader-css-start */
.animated-site-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(255 255 255 / 50%);
  z-index: 999;
  backdrop-filter: blur(4px);
}

.animated__site__logo {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.animated__site__logo svg {
  max-width: 600px;
}

.animated__site__logo .letter__p {
  stroke: #555555;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 206;
  stroke-dashoffset: 0;
  animation-name: letter__p;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes letter__p {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 206;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 412;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 412;
    fill: #555555;
  }
}

.animated__site__logo .letter__a {
  stroke: #555555;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 206;
  stroke-dashoffset: 0;
  animation-name: letter__a;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes letter__a {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 206;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 412;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 412;
    fill: #555555;
  }
}

.animated__site__logo .letter__g {
  stroke: #555555;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 255;
  stroke-dashoffset: 0;
  animation-name: letter__g;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes letter__g {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 255;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 510;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 510;
    fill: #555555;
  }
}

.animated__site__logo .letter__mtr {
  stroke: #555555;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 670;
  stroke-dashoffset: 0;
  animation-name: letter__mtr;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes letter__mtr {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 670;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 1340;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 1340;
    fill: #555555;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.animated__site__logo .grey__box {
  stroke: #555555;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 90;
  stroke-dashoffset: 0;
  animation-name: grey__box;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes grey__box {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 90;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 180;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 180;
    fill: #555555;
  }
}

.animated__site__logo .green__box {
  stroke: #99cc00;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 85;
  stroke-dashoffset: 0;
  animation-name: green__box;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.cs-select option{
  background: #fff;
  color: #000;
}
@keyframes green__box {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 85;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 170;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 170;
    fill: #99cc00;
  }
}

.animated__site__logo .blue__box {
  stroke: #2e9eff;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 70;
  stroke-dashoffset: 0;
  animation-name: blue__box;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes blue__box {
  0% {
    stroke-dashoffset: 0;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 70;
    fill: transparent;
  }

  70% {
    stroke-dashoffset: 140;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 140;
    fill: #2e9eff;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@layer base {
  body {
    @apply bg-primarylightgrey;
    font-family: "Quicksand", sans-serif;
  }
  .cs-react-select svg {
    fill: #fff;
  }
}
